import React from 'react'
import  { Card } from 'react-bootstrap'

import './ServicesCardDeck.css'

const ServicesCardDeck = () => {
    return (
    <>

    <div id="ServicesCardDeck">
        <h2>We Specialise in..</h2>

        <div className="card-deck">
            <Card>
                <Card.Title>
                    <h3>Bridal & Formal Wear</h3>
                </Card.Title>
            </Card>

            <Card>
                <Card.Title>
                    <h3>Leather & Suede Repairs</h3>
                </Card.Title>
            </Card>

            <Card>
                <Card.Title>
                    <h3>Irish Dance Dresses</h3>
                </Card.Title>
            </Card>

            <Card>
                <Card.Title>
                    <h3>Curtain Making Service</h3>
                </Card.Title>
            </Card>

            <Card>
                <Card.Title>
                    <h3>Soft Furnishings</h3>
                </Card.Title>
            </Card>

            <Card>
                <Card.Title>
                    <h3>All Alterations</h3>
                </Card.Title>
            </Card>
        </div>
    </div>
    </>
    )
}

export default ServicesCardDeck
