import React from 'react'
import { Card } from 'react-bootstrap'

import  './ContactUs.css'


const ContactUs = () => {
    return (
    <>
    <div id="ContactUsSection" className="card-deck">

        <Card>
            <Card.Title>
                <i className="fi fi-rr-phone-call"></i>
                <h2>Phone</h2>
                <p>028 90 365 348</p>
                <p>075 23 976 308</p>
            </Card.Title>
        </Card>

        <Card>
            <Card.Title>
                <i className="fi fi-bs-envelope"></i>
                <h2>Email</h2>
                <p><a href="mailto:deborah.kennedy@hotmail.co.uk">email us</a></p>
            </Card.Title>
        </Card>

        <Card>
            <Card.Title>
                <i className="fi fi-rr-at"></i>
                <h2>Social Media</h2>
                <a href="https://www.facebook.com/Zipsandrips">
                    <i className="fi fi-brands-facebook"></i>
                </a>
            </Card.Title>
        </Card>

        <Card>
            <Card.Title>
                <i className="fi fi-rr-time-fast"></i>
                <h2>Opening Hours</h2>
                <p>Mon-Wed  9.30-5.30</p>
                <p>Thurs    9.30-7.00</p>
                <p>Fri      9.30-5.30</p>
                <p>Sat      9.30-2.30</p>
            </Card.Title>
        </Card>

    </div>
    </>
    )
}

export default ContactUs

