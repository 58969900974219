import React from 'react'

import Header           from '../components/Header'
import Introduction     from '../components/Introduction'
import ContactUs        from '../components/ContactUs'
import FindUs           from '../components/FindUs'
import ServicesCardDeck from '../components/ServicesCardDeck'
import Footer           from '../components/Footer'

import './LandingScreen.css'

const LandingScreen = () => {

    return (
    <>
        <Header />

        <ContactUs />

        <FindUs />

        <ServicesCardDeck />

        <Introduction />

        <Footer />
    </>
    )
}

export default LandingScreen
