import React from 'react'

import { Card }  from 'react-bootstrap'


import './FindUs.css'


const FindUs = () => {

    const mapSrc = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1154.049023591544!2d-5.91243!3d54.655099!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4861a771daf86a9f%3A0x41ca592a39b2c72d!2sZips%20%26%20Rips!5e0!3m2!1sen!2sus!4v1694510654606!5m2!1sen!2sus'

    return (
    <>
    <div id="FindUsSection">

        <Card>
            <Card.Title>
                <h2>How to Find Us</h2>
            </Card.Title>

            <Card.Body>
                <p>We&apos;re just 10 minutes from Belfast city centre, and just around the corner from Abbey Centre</p>
            </Card.Body>
        </Card>

        <div id="FindUsMap" className="wow fadeInUp" data-wow-delay=".6s" style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
                <div>
                    <div className="MapCanvas">
                        <div id="gmap-canvas" style={{height: '100%', width:'100%', maxWidth: '100%' }}>
                            <iframe title="findUsMap" style={{height:'100%', width:'100%',border:'0'}} frameBorder="0" src={mapSrc}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </>
    )
}

export default FindUs
