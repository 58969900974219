import React from 'react'

import './Footer.css'


const Footer = () => {
    return (
    <>
        <div className="FooterSection">

            <div className="logoSmall">
                <a href="/"><img src="img/zips_n_rips_small_logo.png" alt="zips and rips" /></a>
            </div>

            <ul>
                <li><a href='/'>home</a></li>
                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                <li><a href='/terms'>Terms of Service</a></li>
            </ul>

        </div>

        <div className="llamaSection">
            &copy; 2023 Zips & Rips, website design by <a href="https://dramallama.ltd">Dramallama Limited</a>
        </div>
    </>
    )
}

export default Footer
