import React from 'react'
import ReactDOM from 'react-dom/client'
import { Helmet } from 'react-helmet'

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";


import './index.css'

import 'bootstrap/dist/css/bootstrap.min.css'

import LandingScreen        from './screens/LandingScreen'
import Basic404Screen       from './screens/Basic404Screen'
import PrivacyPolicyScreen  from './screens/PrivacyPolicyScreen'
import TOSScreen            from './screens/TOSScreen'



const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/'                 element={ <LandingScreen /> }       exact />
      <Route path='/privacy-policy'   element={ <PrivacyPolicyScreen /> } exact />
      <Route path='/terms'            element={ <TOSScreen /> }           exact />

      <Route
        path    ='*'
        element = { <Basic404Screen /> }
        status  = { 404 }
      />
    </>
  )
);


const canonicalHRef = "https://www.zipsandrips.com" + window.location.pathname;

ReactDOM.createRoot(document.getElementById('root')).render(
    <>
      <div>
        <Helmet>
          <link rel="canonical" href={canonicalHRef} />
        </Helmet>
      </div>

      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </>
);
