import React from 'react'

import { Container } from 'react-bootstrap'

import styles  from './Header.module.css'


const Header = () => {
    return (
    <>
        <Container id={styles.Header}>

            <div className={styles.titleImg}>
                <a href="/"><img src="img/zips_n_rips_2.png" alt="Zips and Rips Clothing Alteration & Repair" /></a>
            </div>

            <h2>Custom Clothing Alteration & Repair</h2>

        </Container>
    </>
    )
}

export default Header
