import React from 'react'

import './Introduction.css'


const Introduction = () => {
    return (
        <div id="IntroductionSection" className="Intro">

            <h2>
            Zips And Rips - where every stitch weaves a tale of perfection.
            </h2>

            <h3>Clothing Alterations</h3>
            <p>
                At Zips and Rips, we breathe new life into every piece of attire that comes our way.
                Understanding that every garment tells a story, we specialise in precision alterations,
                ensuring your clothes fit you like a glove, irrespective of the challenge it presents.
            </p>

            <p>
                Whether it&apos;s the delicate replacement of a zipper or mending an unexpected tear, our
                skilled staff work with an combination of tradition and innovation.
                Our commitment to our customers is to turn your favourite clothing into creations
                that showcase your unique style and comfort.
            </p>

            <h3>Irish Dance Dresses</h3>
            <p>
            Our proficiency extends to the vibrant world of Irish dance dresses,
            where we&apos;ve seamlessly merged tradition with contemporary designs, enabling dancers
            to shine on stage with confidence.
            </p>

            <h3>Bridal Gown Alterations</h3>
            <p>
            In the it-must-be-perfect realm of bridal alterations, our
            experienced hands have reshaped countless gowns, ensuring every bride walks down
            the aisle in a dress that feels like it was tailored in heaven for her.
            </p>

            <h3>Formal Wear Alterations</h3>
            <p>
            Formal wear is more than just clothing — it's about moments that matter.
            With a keen understanding of the sophistication and precision formal attire demands,
            our expert tailors meticulously alter and refine each piece to ensure an impeccable
            fit. Whether you're stepping out for a school formal, attending a gala, or celebrating
            a milestone, our formal wear alterations service ensures you do so with confidence,
            draped in perfection.
            </p>

            <h3>Leather & Suede Repair</h3>
            <p>
                Understanding the delicate nature and the ageless elegance of these materials, our skilled
                staff employ techniques honed over years to restore, renew, and rejuvenate your cherished
                pieces. Whether it&apos;s a beloved leather jacket bearing a tear, or suede item showing
                signs of wear, our meticulous attention to detail ensures each item is returned to its
                pristine condition. Trust in our expertise, and let your leather and suede items be reborn
                with Zips and Rips.
            </p>

            <h3>Soft Furnishings Alterations</h3>
            <p>
            Beyond apparel, our talents stretch into homes and spaces with our curtain making and
            alteration services. We reimagine spaces with bespoke curtains tailored to perfection,
            complemented by our exquisite range of soft furnishings that add the finishing touch
            to every room. With us, it&apos;s not just about altering clothes, but about reshaping
            memories, dreams, and living spaces to resonate with personal stories and aspirations.
            </p>

        </div>
    )
}

export default Introduction
