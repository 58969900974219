import React from 'react'

import './Basic404Screen.css'

const Basic404Screen = () => {
    return (
    <>
    <div className="Container" id="Basic404Screen">
      <div className="notfound-404">
        <h1>Your request returned http status code 404</h1>
        <h2>The resource you requested was not found</h2>
      </div>
    </div>
    </>
    )
}

export default Basic404Screen
